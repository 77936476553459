const SUPPORT_QTS_EMAIL = "support@qtech.nyc";
const SUPPORT_QTS_PHONE = "646-980-4233";

const TWITTER_URL = "https://x.com/QuantaTechSys";
const VPN4U_URL = "http://vpn4u.io";
const LAYER_GUARD4U_URL = "http://layerguard4u.io";

const BOOK_CONSULTATION_URL =
  "https://calendly.com/vasyl-zahorodniuk/cybersecurity-consultation";

export {
  SUPPORT_QTS_EMAIL,
  SUPPORT_QTS_PHONE,
  TWITTER_URL,
  VPN4U_URL,
  LAYER_GUARD4U_URL,
  BOOK_CONSULTATION_URL,
};
